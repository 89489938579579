<template>
  <div  class="chart">
    <div class="title">
      <div>
        <span style="margin-right: 15px;">昨日浏览人次(人)</span>
      </div>
      <el-tooltip placement="bottom">
        <div slot="content" style="line-height: 1.5rem">
          昨日浏览人次：<br />统计用户访问该商户商品点击量，每点击一下算一人次
        </div>
        <i class="el-icon-warning-outline" ></i>
      </el-tooltip>
    </div>
    <div class="tip" v-show="true">

    </div>
    <div class="num">1000</div>
    <div id="viewers"></div>
    <div class="bottom">
      较昨日 8.89%
    </div>
  </div>
</template>

<script>
import {Area} from "@antv/g2plot";

export default {
  props:{
    loading: {type: Boolean},
  },
  data(){
    return{
      viewers:null
    }
  },
  mounted() {
    this.changeChart()
    addEventListener('resize',()=>{
      this.changeChart()
    },true)
  },
  methods:{
    async changeChart() {
      const data = [
        { letter: '1', value: 500  },
        { letter: '2', value: 300  },
        { letter: '3', value: 200  },
        { letter: '4', value: 280  },
        { letter: '5', value: 100  },
        { letter: '6', value: 180  },
        { letter: '7', value: 300  },
        { letter: '8', value: 600  },
        { letter: '9', value: 100  },
        { letter: '10', value: 320 },
        { letter: '11', value: 300 },
        { letter: '12', value: 200 },
        { letter: '13', value: 280 },
        { letter: '14', value: 410 },
        { letter: '15', value: 300 },
        { letter: '16', value: 380 },
        { letter: '17', value: 600 },
        { letter: '18', value: 400 },
      ];
      this.viewers && this.viewers.destroy()
      this.viewers = new Area('viewers', {
        data,
        tooltip: false,
        xField: 'letter',
        yField: 'value',
        xAxis: {
          label:null
        },
        yAxis: {
          label:null,
          grid:null,
        },
        areaStyle:{
          fill:'#13C2C2',
          opacity:1,
          fillOpacity:1,
        },
        line:{
          color: '#13C2C2'
        },
        smooth:true
      });
      this.viewers.render();
      this.$emit('update:loading', false)
    }
  }
}
</script>



<style scoped lang="scss">
.chart{
  position: relative;
}
.title{
  color: #666666;
  margin-left: 10px;
  margin-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.num{
  font-size: 32px;
  font-weight: bold;
  margin-left: 10px;
}
#viewers{
  width: 100%;
  height: 60px;
}
.bottom{
  border-top: 1px solid #E8E8E8;
  padding-top: 5px;
  margin: 0 18px;
  font-size: 14px;
}
.el-dropdown-menu__item {
  padding: 0 18px 0 10px;
}
.tip {
  width: 100px;
  position: absolute;
}
</style>